import { useEffect } from "react";
import Footer from "../components/nav/Footer";

const About = ({ token }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="landingpage__container">
        <section className="trading__section">
          <div className="row justify-content-center">
            <div className="col-12 p-0">
              <div className="card trading__header">
                <h3 className="section-title color-white">About Us</h3>
                <p className="section-subtitle color-white">
                  You dont need to be an expert trader to make profit, just copy
                  traders
                </p>
              </div>
              <div className="card trading__body">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-6 col-md-10">
                    <h3 className="section-title text-left">
                      Seven Years of Impeccable Service
                    </h3>
                    <br />
                    <p>
                      Trade Optimal was founded in 2014 by a group of
                      professionals who are artfully combining the technical
                      expertise required to Copy Professional Traders (If you
                      can’t trade on your own). Forex Trading, Crypto Currency
                      Trading and Stock Trading with a dedicated corporate team,
                      state of the art headquarters and a passion for helping
                      those with a strong desire to improve their lives
                      <br />
                      <br />
                      Our team is committed to achieve exceptional results,
                      being one step ahead. We created a trading platform for
                      the long-term, setting up the standard to change the
                      fortune of future generations to come!
                    </p>
                  </div>
                  <div className="col-xl-5 col-lg-5 text-center">
                    <img
                      src="https://res.cloudinary.com/ruthless-labs/image/upload/v1666637000/capitalgains/nb85oxnl9h0n5xbhmims.webp"
                      alt="AboutUsImg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default About;
