import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="container-fluid custom__footer">
        <section className="footer__section">
          <div className="row justify-content-center">
            <div className="col-12 custom__col">
              <div className="card footer__card">
                <div className="row">
                  <div className="col-sm-3 col-12 first__col">
                    <img
                      src="https://res.cloudinary.com/ruthless-labs/image/upload/v1666626037/capitalgains/a4bqnaptvukkkadz2rct.png"
                      alt="LogoImg"
                      className="footerlogo__img"
                    />
                    <p>Trade Optimal</p>
                    <span>2022 © All Rights Reserved</span>
                  </div>

                  <div className="col-sm-3 col-6 second__col">
                    <legend>Quick Links</legend>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-sm-3 col-6 second__col">
                    <legend>Help & Support</legend>
                    <ul>
                      <li>
                        <Link to="/privacypolicy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="/terms">Risk Closure</Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-sm-3 col-6 second__col">
                    <legend>Socials</legend>
                    <ul>
                      <li>
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          Tel: +1-(512)-980-4951, +1 (518) 241-3006
                        </a>
                      </li>
                      <li>
                        <a
                          href="mailto:tradeoptimal@hydrchain.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Email: tradeoptimal@hydrchain.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
      <br />
    </>
  );
};

export default Footer;
