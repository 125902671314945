
import { useEffect } from "react";
import Footer from "../components/nav/Footer";

const Terms = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

	return (
		<>
			<div className="landingpage__container">
				<section className="trading__section">
					<div className="row justify-content-center">
						<div className="col-12 p-0">
							<div className="card trading__header">
								<h3 className="section-title color-white">
									Terms & Conditions
								</h3>
								<p className="section-subtitle color-white">
                                    Last Revised: April 30th, 2022
								</p>
							</div>
							<div className="card trading__body">
                                <div className="row justify-content-center">
                                    <div className="col-xl-11 col-lg-6 col-md-10">
                                        <p>
                                            This Agreement is entered into by and these Terms & Conditions 
                                            (hereinafter referred to as the “Agreement”) shall regulate the 
                                            relationship between TradersHive LTD, (hereinafter referred to as 
                                            the “Company”), and the user (a natural or legal entity) 
                                            (hereinafter referred to as the “Client”) of tradershive.net 
                                            (hereinafter referred as the “Website”).
                                        </p>
                                        <br />
                                        <ul>
                                            <li>The Client confirms that he/she has read, understood and accepted all information, conditions and terms set out on the Website which are open to be reviewed and can be examined by the public and which include important legal Information.</li>
                                            <li>By accepting this Agreement, the Client agrees and irrevocably accepts the terms and conditions contained in this Agreement, its annexes and/or appendices as well as other documentation/information published on the Website, including without limitation to the Privacy Policy, Payment Policy, Withdrawal Policy, Code of Conduct, Order Execution Policy and Anti-Money Laundering Policy. The Client accepts this Agreement by registering an Account on the Website and depositing funds. By accepting the Agreement, and subject to the Company’s final approval, the Client enters into a legal and binding agreement with the Company.</li>
                                            <li>The terms of this Agreement shall be considered accepted unconditionally by the Client upon the Company’s receipt of an advance payment made by the Client. As soon as the Company receives the Client's advance payment, every operation made by the Client on the Trading Platform shall be subject to the terms of this Agreement and other documentation/information on the Website.</li>
                                            <li>The Client hereby acknowledges that each and any Operation, activity, transaction, order and/or communication performed by him/her on the Trading Platform, including without limitation through the Account, and the Website, shall be governed by and/or must be executed in accordance with the terms and conditions of this Agreement and other documentation/information on the Website.</li>
                                            <li>By accepting this current agreement, the Client confirms that he/she is able to receive information, including amendments to the present Agreement either via email or through the Website.</li>
                                            <li>A client that is a legal entity can register with the Company not through the Website but by sending an email with its request toinfo@tradershive.net. All terms and and conditions contained herein, including without limitation to 1 to 5 above, shall at all times be applicable to the Legal Entity and the latter shall conform with such terms and conditions, obligations and rights at all times.</li>
                                        </ul>
                                        <h3 className="section-title text-left">
                                            Terms
                                        </h3>
                                        <br />
                                        <ul>
                                            <li>Account – means a unique personified account registered in the name of the Client and which contains all of the Client’s transactions/ operations on the Trading Platform (as defined below) of the Company.</li>
                                            <li>Ask - means the higher price in a quote. The price the Client may buy at.</li>
                                            <li>Bid - means the lower price in a quote. The price the Client may sell at.</li>
                                            <li>CFD (contract for difference) - means a tradeable contract entered into between the Client and the Company, who exchange the difference in the value of an Instrument, as specified on the Trading Platform at the time of opening a Transaction, and the value of that Instrument at the contract’s end.</li>
                                            <li>Digital Option Contract - means a type of derivative instrument where the Client earns a payout if they correctly predict the price movement of the underlying asset at the time of the option’s expiry. The prediction can be made as to whether the value of the underlying asset will fall above or below the strike price at time of expiration. Should the option expire at the selected strike price, it will be considered to expire out-of-the money and will result in the loss of the invested amount.</li>
                                            <li>Execution - means the execution of Client order(s) by the Company acting as the Client's counterparty as per the terms of the present agreement.</li>
                                            <li>Financial Instruments - means the Financial Instruments as per paragraph 2.4 below that are available on the Company’s Trading Platform.</li>
                                            <li>KYC documents - means the documents to be provided by the Client, including without limitation to the a copy of the passport or ID and utility bill of the Client, in case it is a natural person and/or certificates showing the management and ownership going all the way up to the ultimate beneficial owner, in case it is a legal entity, and any other documents the Company may request upon its sole discretion</li>
                                            <li>Market - means the market on which the Financial Instruments are subject to and/or traded on, whether this market is organized / regulated or not and whether it is in St. Vincent and the Grenadines or abroad.</li>
                                            <li>Market Maker - means a company which provides BID and ASK prices for financial instruments.</li>
                                            <li>Operations – means actions performed at the Client’s Account, following an order placed by the Client,, connected with but not limited to crediting of funds, return of funds, opening and closing of trade transactions/positions and/or that relate to financial instruments.</li>
                                            <li>Prices - means the prices offered to the Client for each transaction which may be changed without prior notice. Where this is relevant, the “Prices” given through the Trading Platform include the Spread (see definition below).</li>
                                            <li>Services – means the services described in section 3 of this Agreement.</li>
                                            <li>Spread - means the difference between the purchase price Ask (rate) and the sale price Bid (rate) at the same moment. For avoidance of doubt, a predefined spread is for the purposes of this Agreement assimilated commission.</li>
                                            <li>Trading Platform - means an electronic system on the internet that consists of all programs and technology that present quotes in real-time, allow the placement/modification/deletion of orders and calculate all mutual obligations of the Client and the Company.</li>
                                        </ul>
                                        <br />
                                        <h3>
                                            Subject of the Agreement
                                        </h3>
                                        <br />
                                        <ul>
                                            <li>The subject of the Agreement shall be the provision of Services to the Client by the Company under the Agreement and through the Trading Platform.</li>
                                            <li>The Company shall carry out all transactions as provided in this Agreement on an execution-only basis, neither managing the account nor advising the Client. The Company is entitled to execute transactions requested by the Client as provided in this Agreement even if the transaction is not beneficial for the Client. The Company is under no obligation, unless otherwise agreed in this Agreement and/or other documentation/information on the Website, to monitor or advise the Client on the status of any transaction, to make margin calls, or to close out any of the Client’s open positions. Unless otherwise specifically agreed, the Company is not obligated to make an attempt to execute the Client’s order using quotes more favorable than those offered through the Trading Platform.</li>
                                        </ul>
                                        <br />
                                        <h3>
                                            General Provisions
                                        </h3>
                                        <br />
                                        <ul>
                                            <li>The Company will offer Services to the Client at the absolute discretion of the Company subject to the provisions of this Agreement.</li>
                                            <li>The Client is prohibited and shall not, under no circumstances, be allowed to execute any transactions/Operations on the Trading Platform, Website and/or through his/her Account, that would as a result exceed the total balance and/or amount of money deposited/maintained with his/her Account. Such deposited amounts shall be considered to have been provided as collateral, either in the form of a lien or otherwise, to the Company by the Client by which the obligation of the Client to pay any money to the Company is secured.</li>
                                        </ul>
                                        <br />
                                        <h3>
                                            Services of the Company
                                        </h3>
                                        <br />
                                        <ul>
                                            <li>Services – services provided by the Company to the Client through the Trading Platform of the Company, including without limitation to customer, analytics, news and marketing information services</li>
                                            <li>The Company shall facilitate the execution of trade activities/orders and/or transactions of the Client but the Client hereby acknowledges and accepts that the Company shall not at any time provide any trust services and/or trading consultation or advisory services to the Client.</li>
                                            <li>The Company shall process all transactions/Operations of the Client in accordance with the terms and conditions of this Agreement and on an execution-only basis. The Company shall not manage the Client’s Account nor advise the Client in any way.</li>
                                            <li>The Company shall process the orders/transactions requested by the Client under this Agreement irrespective of whether such orders/transactions may result to not being beneficial for the Client. The Company is under no obligation, unless otherwise agreed in this Agreement and/or other documentation/information on the Website, to monitor or advise the Client on the status of any transaction/order, to make margin calls to the Client, or to close out any of the Client’s open positions. Unless otherwise specifically agreed, the Company is not obligated to process or attempt to process the Client’s order/transaction using quotes more favorable than those offered through the Trading Platform.</li>
                                            <li>The Company shall not be financially liable for any operations conducted by the Client through the Account and/or on the Trading Platform.</li>
                                            <li>Each Client shall be the only authorized user of the Company’s services and of the corresponding Account. The Client is granted an exclusive and non-assignable right to the use of and to access the Account and it is his/her responsibility to ensure that no other third party, including, without limitation, to any next of kin and/or to members of his/her immediate family, shall gain access to and/or trade through the Account assigned to her/him.</li>
                                            <li>The Client has the right to cancel his order given to the Company within 3 seconds after the moment of giving such order to the Company (hereinafter referred to as the “Cancellation”). The client agrees and understands that the three seconds cancellation option offered by the Company is applicable and available for the client as long as the price remains unchanged. Three seconds from the moment of giving the order to the Company by the Client via the platform, the Company may (but is not obliged to) offer to buyout the option from the Client and the Client have the right to agree to such offer (hereinafter referred to as the “Buyout”). The Client is entitled to use such Cancellation or Buyout option subject to the conditions specified on the platform. Such conditions can also include the fee charged by the Company. Such fee is specified on the platform. The Company is obliged to provide all necessary information as to the conditions of Cancellation and Buyout, their cost, etc. The Client acknowledges and agrees that provision of such information on the platform is sufficient. The Client acknowledges and agrees that the use of Cancellation or Buyout is very risky to the Client as long as the cost of Cancellation and/or Buyout depends on the market situation. The Client acknowledges and agrees that he bears all the risks associated with the use of Cancellation and/or Buyout.</li>

                                        </ul>
                                        <br />
                                        <h3>
                                            Execution of Orders / Electronic Trading
                                        </h3>
                                        <br />
                                        <ul>
                                            <li>By accepting this Agreement, the Client accepts that he has read and understood all provisions of this Agreement and related information on the Website. The Client accepts and understands that all orders received shall be executed by the Company as the counterparty of the transaction in its capacity of Market Maker. The Company shall act as a principal and not as an agent on the Client’s behalf for the purpose of the Execution of orders. The Client is informed that Conflicts of Interest may arise because of this model.</li>
                                            <li>Reception of the order by the Company shall not constitute acceptance and acceptance shall only be constituted by the execution of the order by the Company.</li>
                                            <li>The Company shall be obliged to execute the Client's orders sequentially and promptly.</li>
                                            <li>The Client acknowledges and accepts a) the risk of mistakes or misinterpretations in the orders sent through the Trading Platform due to technical or mechanical failures of such electronic means, b) the risk of any delays or other problems as well as c) the risk that the orders may be placed by persons unauthorised to use and/or access the Account, and the Client agrees to indemnify the Company in full for any loss incurred as a result of acting in accordance with such orders.</li>
                                            <li>The Client accepts that during the reception and transmission of his/her order, the Company shall have no responsibility as to its content and/or to the identity of the person placing the order, except where there is gross negligence, willful default or fraud by the Company.</li>
                                            <li>The Client acknowledges that the Company will not take action based on the orders transmitted to the Company for execution by electronic means other than those orders transmitted using the predetermined electronic means such as the Trading Platform, and the Company shall have no liability towards the Client for failing to take action based on such orders.</li>
                                            <li>The client acknowledges and agrees that any products or services that may be offered by the Company may not always be available for purchasing or use for trading purposes, and it is in the Company's absolute discretion whether it will make these products available or not to the clients at any time. The Company shall bear no liability, monetary or otherwise, in relation to this section, including without limitation to not making available any product at any given time.</li>
                                        </ul>
                                        <br />
                                        <h3>Personal Data</h3>
                                        <br />
                                        <ul>
                                            <li>By accepting the terms and conditions of this Agreement, the Client irrevocably consents to the collection and processing of his/her personal data/information by the Company without the use of automatic controls, as the same are provided by him/her to the Company. The term personal data for the purposes of this Agreement shall mean: the Name, Surname, Patronymic, gender, address, phone number, e-mail, IP address of the Client, Cookies and information that relate to the provision of Services to the Client (for example, the Client’s trading story).</li>
                                            <li>The Client shall be obliged to provide correct, accurate and complete personal data/information as requested by the Company.</li>
                                            <li>The purpose of collecting and processing the personal data is to comply with applicable regulating legislation requirements, including without limitation to anti-money laundering regulations, as well as for any and all purposes in relation to this Agreement, including without limitation to enable the Company to discharge its obligations towards the Client.</li>
                                            <li>The Client acknowledges and consents to that, for the purposes described at the section directly above, the Company shall be entitled to collect, record, systematize, accumulate, store, adjust (update, change), extract, use, transfer (disseminate, provide, access), anonymize, block, delete, destroy such personal data and/or perform any other actions according to the current regulating legislation.</li>
                                            <li>The Client acknowledges and consents to the Company storing, maintaining and processing his/her personal data in the manner as described in this Agreement during the term of the Agreement and for 5 years following any termination of the Agreement.</li>
                                        </ul>
                                        <br />
                                        <h3>Risk Statement</h3>
                                        <br />
                                        <ul>
                                            <li>The Client hereby confirms to have read, understood and hereby accepts the risk statement relating to the use of Services on the Website, as the same is available electronically via the Website.</li>
                                            <li>The Client hereby confirms to have read, understood and hereby accepts the risk statement relating to the use of Services on the Website, as the same is available electronically via the Website.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
		</>
	)
}

export default Terms;