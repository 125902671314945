import { useEffect } from "react";
import Footer from "../components/nav/Footer";

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

	return (
		<>
			<div className="landingpage__container">
				<section className="trading__section">
					<div className="row justify-content-center">
						<div className="col-12 p-0">
							<div className="card trading__header">
								<h3 className="section-title color-white">
									Privacy Policy
								</h3>
								<p className="section-subtitle color-white">
                                    Last Revised: February 5, 2022
								</p>
							</div>
							<div className="card trading__body">
                                <div className="row justify-content-center">
                                    <div className="col-xl-11 col-lg-6 col-md-10">
                                        <h3 className="section-title text-left">
                                            Introduction
                                        </h3>
                                        <br />
                                        <p>
                                            TradersHive Limited (in incorporation) (“us“, “we” or “Company“) respect 
                                            the privacy of our users (each, “you” or “User“) and are committed to 
                                            protect the privacy of Users who access, download, install or register 
                                            to our mobile application (the “Application“), our website or any other 
                                            online services we provide (collectively: the “Services“). 
                                            The Company has papered this Privacy Policy to outline our practices 
                                            with respect to collecting, using and disclosing your information when 
                                            you use the Services. We encourage you to read the Privacy Policy 
                                            carefully and use it to make informed decisions.
                                            By using the Services, you agree to the terms of this Privacy Policy 
                                            and your continued use of the Services constitutes your ongoing 
                                            agreement to the Privacy Policy. The Privacy Policy is a part of 
                                            the Terms of Service and is incorporated there in by reference. 
                                            In this Privacy Policy you will read about, among other things:
                                        </p>
                                        <br />
                                        <p>* What type of information we collect</p><br />


                                        <p>* Cookies and Google Analytics</p><br />


                                        <p>* How we use the information</p><br />


                                        <p>* With whom we share the information and for what purpose</p><br />


                                        <p>* For how long we retain the information</p><br />


                                        <p>* How we protect your information</p><br />


                                        <p>* Advertisements</p><br />


                                        <p>* Advertising ID and Advertising Identifier</p><br />


                                        <p>* How to contact us</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
		</>
	)
}

export default PrivacyPolicy;