import { useEffect } from "react";
import Footer from "../../components/nav/Footer";

const Stocks = ({token}) => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

	return (
		<>
			<div className="landingpage__container">
				<section className="trading__section">
					<div className="row justify-content-center">
						<div className="col-12 p-0">
							<div className="card trading__header">
								<h1 className="section-title color-white">
									Stocks for Your Portfolio
								</h1>
								<p className="section-subtitle color-white">
									Trade stocks or make long-term investments 
									on the same platform
								</p>
							</div>
							<div className="card trading__body">
								<div>
									<h3>What is CFD?</h3>
									<p>
										A CFD, or Contract for Difference, is a type of 
										financial instrument that allows you to trade 
										on the price movements of stocks, regardless of 
										whether prices are rising or falling. The key 
										advantage of a CFD is the opportunity to 
										speculate on the price movements of an asset 
										(upwards or downwards) without actually owning 
										the underlying asset.
									</p>
								</div>

								<div>
									<p>
										Stock trading has been a popular financial pursuit 
										since stocks were first introduced by the Dutch 
										East India Company in the 17th century. This is 
										both an efficient and effective type of investment 
										for both families and individuals.
									</p>
								</div>


								<div>
									<h3>What Are Stocks?</h3>
									<p>
										Stocks, also commonly referred to as equities or 
										shares, are issued by a public corporation and put 
										up for sale. Companies originally used stocks as a 
										way of raising additional capital, and as a way to 
										boost their business growth. When the company 
										first puts these stocks up for sale, this is 
										called the Initial Public Offering. Once this 
										stage is complete, the shares themselves are 
										then sold on the stock market, which is where 
										any stock trading will occur.
									</p>
								</div>

								<div>
									<p>
										People occasionally confuse buying shares with 
										physically owning a portion of that company as 
										if this somehow gives them the right to walk 
											into the company offices and begin exerting 
										their ownership rights over computers or furniture. 
										The law treats this type of corporation in a 
										unique way; as it is treated as a legal person, 
										the corporation, therefore, owns its own assets. 
										This is referred to as the separation of 
										ownership and control.
									</p>
								</div>

								<div>
									<p>
										The separation of these things is beneficial to 
										both the shareholders and the corporation because 
										it limits the liability for each party. For 
										example, if a major shareholder were to go 
										bankrupt, they cannot then sell assets 
										belonging to the corporation to cover their 
										debts and pay their creditors. This is the 
										same in reverse; if a corporation you own shares 
										in goes bankrupt and the judge orders them to 
										sell all their assets, none of your own personal 
										assets are at risk.
									</p>
								</div>

								<div>
									<h3>How Do I Trade Stocks?</h3>
									<p>
										A stock market is where stocks are traded: where 
										sellers and buyers come to agree on a price. 
										Historically, stock exchanges existed in a 
										physical location, and all transactions took place 
										on the trading floor. One of the world’s most 
										famous stock markets is the London Stock 
										Exchange (LSE).
									</p>
								</div>
								
								<div>
									<p>
										Yet as technology progresses, so does the stock 
										market. Now we are seeing the rise of virtual 
										stock exchanges that are made up of large 
										computer networks will all trades performed 
										electronically.
									</p>
								</div>

								<div>
									<p>
										A company's shares can be traded on the stock 
										market only following its IPO, making this a 
										secondary market. The large businesses listed 
										on global stock exchanges do not trade stocks 
										on a frequent basis. Stocks can only be purchased 
										from an existing shareholder, not directly 
										from the company. This rule also applies in 
										reverse, so when selling your shares, they go 
										to another investor, not back to the corporation.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

            <Footer />
		</>
	)
}

export default Stocks;