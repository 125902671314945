import { useEffect } from "react";
import Footer from "../../../components/nav/Footer";

const AboutMining = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="landingpage__container">
        <section className="trading__section">
          <div className="row justify-content-center">
            <div className="col-12 p-0">
              <div className="card trading__header">
                <h1 className="section-title color-white">Crypto Mining</h1>
                <p className="section-subtitle color-white">
                  Get to know a new idea of money
                </p>
              </div>
              <div className="card trading__body">
                <div>
                  <h3 className="text-center">Why Trade Optimal?</h3>
                  <p className="text-center">
                    We are uniting all key aspects of running an efficient
                    cryptocurrency mining operation.
                  </p>
                </div>

                <div className="row justify-content-center">
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="card cryptomining__card">
                      <h3 className="card-title">
                        We make mining competitive.
                      </h3>
                      <p className="card-subtitle">
                        To mine competitively today, you need to invest
                        significant resources, time and effort into your setup.
                        Our team has built the most efficient mining systems to
                        do the job for you. This way you can fully focus on
                        keeping track of the markets and remain competitive with
                        your mining rewards.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="card cryptomining__card">
                      <h3 className="card-title">
                        Contributing to the ecosystem.
                      </h3>
                      <p className="card-subtitle">
                        Besides being the portal for interesting mining data, we
                        are also actively contributing to the cryptocurrency
                        ecosystem, from launching awareness campaigns to
                        releasing open-source mining software.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="card cryptomining__card">
                      <h3 className="card-title">Multi-algorithm support.</h3>
                      <p className="card-subtitle">
                        We are building mining data centers around the world
                        that are able to support 6 mining algorithms for 10+
                        different cryptocurrencies. If that’s not enough, we’d
                        be happy to also support the ones you want to mine!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default AboutMining;
