import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";

const PricingCard = ({ plan }) => {
  let nairaConvert = Intl.NumberFormat("en-NG");

  return (
    <div
      data-aos="slide-up"
      data-aos-duration="1000"
      className="card pricing__listcard"
    >
      <h3 className="package__type">{plan.type}</h3>
      <p className="package__pricing">
        <FaIcons.FaDollarSign />
        {nairaConvert.format(plan.price)}
      </p>
      <span></span>
      <ul>
        {plan.benefits.map((benefit, index) => {
          return <li key={index}>{benefit}</li>;
        })}
      </ul>
      <Link to={`/user/deposits/crypto?amount=${plan.price}&to=1`}>
        Invest With Card
      </Link>
    </div>
  );
};

export default PricingCard;
