import { useEffect } from "react";
import Footer from "../../../components/nav/Footer";

const DogecoinMining = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

	return (
		<>
			<div className="landingpage__container">
				<section className="trading__section">
					<div className="row justify-content-center">
						<div className="col-12 p-0">
							<div className="card trading__header">
								<h1 className="section-title color-white">
									Dogecoin Mining
								</h1>
							</div>
							<div className="card trading__body">
								<div>
									<p>
										Bitcoin has a public ledger which is called the 
										blockchain. The process of mining adds new transactions 
										to this public ledger. Why? Well, Bitcoin users need 
										this process because it means that every transaction 
										is securely confirmed and verified while all the 
										users making use of the Bitcoin network has full 
										access to the blockchain – in other words, the 
										Bitcoin ledger. Mining also helps the network figure 
										out which transactions are fair and legit, eliminating 
										any transactions that try to spend money a second time.
									</p>
								</div>

								<div>
									<p>
										So when someone “mines” Bitcoin they are in fact 
										performing a service to all Bitcoin users because 
										they ensure Bitcoin transactions are legitimate. 
										During the process of mining people who mine Bitcoin 
										will complete a new block which means that the miner 
										gets a reward. In 2018 the return for completing 
										a new block was 12.5 Bitcoin, but the reward gets 
										lower with time.
									</p>
								</div>

								<div>
									<p>
										As you can imagine, mining requires a lot of hard 
										work and patience – you do not get Bitcoin mining 
										results quickly. So there’s an obvious analogy 
										with actual, physical mining of metals like gold. 
										Hence using the word “mining” for the computational 
										tasks that generate new Bitcoin.
									</p>
								</div>

								<div>
									<p>
										Choosing your mining equipment
										<br />
										Mining Bitcoin involves very complex calculations 
										which are very computationally intensive. So, 
										choosing the right hardware kit when you mine 
										Bitcoin is really essential. You need to think 
										about a number of specific characteristics when 
										you choose your Bitcoin mining kit.
									</p>
								</div>

								<div>
									<p>
										Hash rates
										<br />
										Perhaps the key aspect of your mining kit choice is 
										this: the hash rate that your mining hardware can 
										sustain. Hash rate is basically the number of crypto 
										calculations that your mining hardware can perform 
										every second. It’s easy to see why a higher hash 
										rate will help you mine coins more quickly – simply 
										because more calculations per second mean that you 
										solve the crypto math required to mine a coin much 
										more quickly. As a result you can quickly complete 
										a block and get your reward for doing so.
									</p>
								</div>

								<div>
									<p>
										Hash functions work like this: whatever input you 
										insert will always give you the same output. So to 
										find a specific output you have to try as many 
										random inputs as you can – as fast as you can. 
										It makes mining competitive and the miner who can 
										process more inputs than other miners will end up 
										getting rewarded faster. So, again, if you can get 
										hardware with a high hash rate you will have an 
										advantage over other miners which means you get 
										more rewards more quickly.
									</p>
								</div>

								<div>
									<p>
										The rate of measurements for hash rates is MH/sec, 
										which is short for megahashes per second. You can 
										also measure hash rates in terms of GH/sec and 
										TH/sec, standing for giga- and terahashes per 
										second respectively. Bitcoin mining hardware have 
										hash rates which can range from a few hundred 
										MH/sec all the way to 10 TH/sec (or 10,000,000 MH/sec).
									</p>
								</div>

								<div>
									<p>
										Cost of energy
										<br />
										There are costs involved with mining Bitcoin, 
										and it’s not just the physical mining hardware 
										you need to worry about. If you can afford powerful 
										hardware you will quickly find you have another 
										headache: the electricity cost associated with 
										driving that hardware because powerful mining 
										hardware consume a lot of power.
									</p>
								</div>

								<div>
									<p>
										When buying hardware you therefore need a close 
										look at the electricity consumption of the kit – 
										work it out in watts and then see how much it 
										will cost you, so that you don’t get a big surprise 
										when you get your next electricity bill. If you 
										don’t you risk spending all your mining profits 
										on electricity – or indeed face making a big loss.
									</p>
								</div>

								<div>
									<p>
										Hash rate alongside energy consumption is a good 
										way to evaluate mining profits. Compare the hashes 
										you can complete in an hour with the cost of 
										electricity per hour (or per day). An easy way 
										to do this is to divide the hash rate of your kit 
										by the watts consumed. It’ll give you a MH/s per 
										watt rate which can guide you, alongside current 
										electricity costs, to find whether your mining kit
										will produce a profit.
									</p>
								</div>

								<div>
									<p>
										Don’t forget to include extra costs like the computer 
										hardware that drives mining GPUs when you calculate 
										profits - your PC will also consume power on top 
										of the mining hardware that you have.
									</p>
								</div>

								<div>
									<p>
										Bitcoin mining hardware options
										<br />
										When Bitcoin was just released a wide variety of 
										people paid attention as it was a unique idea 
										that people found very liberal. In any case, 
										Bitcoin was very revolutionary compared to the 
										way transactions were processed before: by 
										centralised banks. This self-governing network was 
										outside the remit of financial institutions, 
										tax authorities and other big organisations 
										because it was completely decentralised.
									</p>
								</div>

								<div>
									<p>
										In these early days of Bitcoin less people knew 
										about the cryptocurrency and fewer people were 
										buying and investing in Bitcoin, so the value of 
										Bitcoin was not as high. The result was that it 
										was easy to mine Bitcoin which meant that there 
										were many miners interested in mining Bitcoin for 
										profit, but they were also interested in Bitcoin 
										because it was such an incredible, novel idea. 
										Back then, mining Bitcoin required the use of 
										basic computing power – even a laptop was enough, 
										or a powerful desktop computer.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<Footer />
		</>
	)
}

export default DogecoinMining;