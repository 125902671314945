import Footer from "../components/nav/Footer";

const WhyUs = () => {

    const whyUsList = [
        {
            title: "CORPORATE STRATEGY",
            body: `Our team is charged with transforming our company to lead in an ever-changing world by 
                providing business analysis and corporate strategy guidance, including informing management 
                decision-making and communicating with external stakeholders`
        },
        {
            title: "BUSINESS OPERATIONS",
            body: `Our global network of operations professionls centralize knowledge, equip teams with data and 
                technology, and provide the information needed to innovate and deliver for internal stakeholders and clients`
        },
        {
            title: "HUMAN RESOURCES",
            body: `Our work focuses on attracting and developing a diverse workforce. We strive to make our 
                company an inclusive place to work twhere every employee feels like they belong and can meaningfully 
                contribute to advancing our purspose`
        },
        {
            title: "INVESTMENTS",
            body: `We are helping our clients solve their most important investment challenges and strive to 
                deliver persistent, long term investment performance. Our investment professionals benefit from the 
                resources of a world-class technology firm that is differentiated by a team approach, 
                integrated research and risk management`
        }
    ]

    return (
        <>
            <div className="row">
                <div className="col-12 p-0">
                    <nav className="pages__nav">
                        <h3>WHY CHOOSE US</h3>
                    </nav>
                </div>
            </div>
            <div className="landingpage__container">
                <section className="pricing__section iv__programs">
                    <div className="pricing__list">
                        <div className="row">
                        {
                            whyUsList.map((content, index) => {
                                return (
                                    <div
                                        className="col-sm-6 p-0"
                                        key={index}
                                    >
                                        <WhyUsCard
                                            content={content}
                                        />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                </section>
            </div>
            <br /><br />
            <Footer />
        </>
    )
}

const WhyUsCard = ({content}) => {
    return (
        <div
            data-aos="slide-up"
            data-aos-duration="1000"
            className="card whyus__listcard"
        >
            <h4>{content.title}</h4>
            <p>{content.body}</p>
        </div>
    )
}

export default WhyUs;