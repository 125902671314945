import { Link } from "react-router-dom";
import * as VscIcons from "react-icons/vsc";
import AuthNav from "./AuthNav";
import Sidebar from "./Sidebar";
import { useState } from "react";

const Navbar = ({ removeToken, successToast, currentUser, token }) => {
  const [sidebarState, setSidebarState] = useState(false);

  const toggleSidebar = (e) => {
    setSidebarState(!sidebarState);
  };

  return (
    <>
      {sidebarState ? (
        <Sidebar
          toggleSidebar={toggleSidebar}
          token={token}
          successToast={successToast}
          removeToken={removeToken}
          currentUser={currentUser}
        />
      ) : (
        ""
      )}
      {token ? (
        <AuthNav
          token={token}
          successToast={successToast}
          currentUser={currentUser}
          removeToken={removeToken}
        />
      ) : (
        <nav className={`navbar navbar-expand custom__nav`}>
          <ul className="mobile_view">
            <li>
              <Link to="/">
                <img
                  src="https://res.cloudinary.com/ruthless-labs/image/upload/v1666626037/capitalgains/a4bqnaptvukkkadz2rct.png"
                  alt="Trade Optimal Logo"
                  width={640}
                  height={320}
                />
              </Link>
            </li>
            <li onClick={toggleSidebar}>
              <span>
                <VscIcons.VscThreeBars />
              </span>
            </li>
          </ul>
          <ul className="nav_chart">
            <li>
              <img
                src="https://res.cloudinary.com/ruthless-labs/image/upload/v1666626037/capitalgains/a4bqnaptvukkkadz2rct.png"
                alt="Trade Optimal Logo"
                width={640}
                height={320}
              />
            </li>
            <li>
              <span>+5.26%</span>
              <span>24 hour price</span>
            </li>
            <li>
              <span>12.820BTC</span>
              <span>24 hour volume</span>
            </li>
            <li>
              <span>2,231,775</span>
              <span>active traders</span>
            </li>
            <li>
              <span>63,971.68</span>
              <span>Live Bitcoin price</span>
            </li>
            <li>
              <Link to="/auth/signup">Invest</Link>
            </li>
          </ul>
          <ul className="nav_ul">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/investment-programs">Investment Programs</Link>
            </li>
            <li>
              <Link to="/why-us">Why Choose Us</Link>
            </li>
            <li>
              <Link to="/auth/signup">Sign Up</Link>
            </li>
            <li>
              <Link to="/auth">Sign In</Link>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default Navbar;
