import { useEffect } from "react";
import Footer from "../../components/nav/Footer";

const CopyTrading = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

	return (
		<>
			<div className="landingpage__container">
				<section className="trading__section">
					<div className="row justify-content-center">
						<div className="col-12 p-0">
							<div className="card trading__header">
								<h3 className="section-title color-white">
									Copy Expert Traders
								</h3>
								<p className="section-subtitle color-white">
									You do'nt need to be an expert trader to 
									make profit, just copy traders
								</p>
							</div>
							<div className="card trading__body">
								<div>
									<p>
										It’s all in the name! Copy trading allows you to 
										directly copy the positions taken by another trader. 
										You decide the amount you wish to invest and simply 
										copy everything they do automatically in real-time – when 
										that trader makes a trade, your account will make that 
										same trade as well. You do not need to have any input 
										on the trades, and you get the identical returns on 
										each trade as your chosen trader. But by copying 
										another trader, you could potentially make money 
										based on their skills. In fact, no advanced knowledge 
										of the financial market is required to take part!
									</p>
								</div>

								<div>
									<p>
										The history of copy trading goes back to 2005 when 
										traders used to copy specific algorithms that were 
										developed through automated trading. Brokers 
										recognised the potential of having systems where 
										any linked to that trader could automatically copy 
										their trading account. There was no need to constantly 
										monitor email signals or trading "chat" rooms. 
										We think they were onto something. Out of this were 
										born services that allowed traders to connect their 
										personal trading accounts to their platform. Traders 
										no longer had to submit their specific strategies. 
										The popularity of copy trading has since exploded.
									</p>
								</div>

								<div>
									<p>
										1 in 3 say a traditional stock market approach is 
										over-complex and this can be simplified by automatically 
										following traders. 1 in 4 investors said they were 
										considering Copy trading last year
									</p>
								</div>

								<div>
									<p>
										Here is how the copier works: You, as an investor, 
										simply select an expert or experts that you want 
										to copy trades from. Once you are signed up, this 
										is the only action needed on your part.
									</p>
								</div>

								<div>
									<p>
										Once you’ve taken care of the above, you are all set. 
										There are no codes that you need to run or signals 
										for you to manually input. Our software will handle 
											the trade copying automatically on your behalf. 
										We monitor your experts trading activity and as soon 
										as there is a trade, we calculate all the necessary 
										parameters and execute the trade.
										<br />
										<br />
										The only thing you have to make sure of is that you 
										have enough funds available in your trading account. 
										When the expert exits a position, you too will exit it. 
										Automatically.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			
			<Footer />
		</>
	)
}

export default CopyTrading;