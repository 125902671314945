import PricingCard from "../components/card/PricingCard";
import Footer from "../components/nav/Footer";

const InvestmentPrograms = () => {

    const pricingPlans = [
        {
            price: 500,
            earnPrice: 5000,
            type: "bronze",
            package: "minimum",
            deposits: {
                minDeposit: 499,
                maxDeposit: 4999
            },
            benefits: ["Unlimited support", "Accessible on all devices", "Easy to use", "Withdraw directly to your wallet"]
        },
        {
            price: 5000,
            earnPrice: 15000,
            type: "silver",
            package: "minimum",
            deposits: {
                minDeposit: 4999,
                maxDeposit: 14999
            },
            benefits: ["Unlimited support", "Accessible on all devices", "Easy to use", "Withdraw directly to your wallet"]
        },
        {
            price: 20000,
            earnPrice: 45000,
            type: "gold",
            package: "minimum",
            deposits: {
                minDeposit: 19999,
                maxDeposit: 44999,
            },
            benefits: ["Unlimited support", "Accessible on all devices", "Easy to use", "Withdraw directly to your wallet"]
        },
        {
            price: 30000,
            earnPrice: 75000,
            type: "platinum",
            package: "minimum",
            deposits: {
                minDeposit: 29999,
                maxDeposit: 74999,
            },
            benefits: ["Unlimited support", "Accessible on all devices", "Easy to use", "Withdraw directly to your wallet"]
        }
    ]

    return (
        <>
            <div className="row">
                <div className="col-12 p-0">
                    <nav className="pages__nav">
                        <h3>INVESTMENT PACKAGES</h3>
                    </nav>
                </div>
            </div>
            <div className="landingpage__container">
                <section className="pricing__section iv__programs">
                    <div className="pricing__list">
                        <div className="row">
                        {
                            pricingPlans.map((plan, index) => {
                                return (
                                    <div
                                        className="col-md-3 col-sm-6"
                                        key={index}
                                    >
                                        <PricingCard
                                            plan={plan}
                                        />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                </section>
            </div>
            <br /><br />
            <Footer />
        </>
    )
}

export default InvestmentPrograms;