import { Link } from "react-router-dom";
import { useState } from "react";
import * as MdIcons from "react-icons/md";
import * as GoIcons from "react-icons/go";
import * as GiIcons from "react-icons/gi";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";
import * as RiIcons from "react-icons/ri";

const Sidebar = ({toggleSidebar, token, currentUser, removeToken, successToast}) => {

	const [pageDropdown, setPageDropdown] = useState(false);
	const [tradingDropdown, setTradingDropdown] = useState(true);
	const [miningDropdown, setMiningDropdown] = useState(true);

	const togglePageDropdown = (e) => {
		e.preventDefault();
		setPageDropdown(!pageDropdown);
	}

	const toggleTradingDropdown = (e) => {
		e.preventDefault();
		setTradingDropdown(!tradingDropdown);
	}

	const toggleMiningDropdown = (e) => {
		e.preventDefault();
		setMiningDropdown(!miningDropdown);
	}

	const logoutUser = (e) => {
		e.preventDefault();
		successToast("Successfully logged out -----");
		removeToken();
	}

	return (
		<div className="sidebar__frame fixed-top">
			<div className="sidebar__overlay fixed-top" onClick={toggleSidebar}></div>
			<div className="sidebar__card">
				<button
					id="sidebarclose__button"
					onClick={toggleSidebar}
					title="close button"
				>
					Close
				</button>
				{
					token?
					<div id="sidebarauth__profile">
						<img
							src={currentUser.profile_photo}
							alt="CurrentUserPhoto"
						/>
						<span>{currentUser.username}</span>
						<span>{currentUser.email}</span>
					</div>
					:
					<div className="sidebarlogo__div">
						<img
							src="https://res.cloudinary.com/ruthless-labs/image/upload/v1666626037/capitalgains/a4bqnaptvukkkadz2rct.png"
							alt="LogoImg"
						/>
					</div>
				}

				<div id="sidebar__listdiv">
					<ul className="sidebar__list">
						<li className="nav-item">
							<Link
								to="/pages"
								className="nav-item-link"
								onClick={togglePageDropdown}>
								<MdIcons.MdArrowDropDown /> Pages
							</Link>
							{
								token?
								<>
								<ul className="dropdown__ul" hidden={pageDropdown}>
									<li>
										<Link
											to={currentUser.permission==="user"?"/user":"/app"}
											onClick={toggleSidebar}
										>
											<AiIcons.AiOutlineDashboard />
											Dashboard
										</Link>
									</li>
									{
										token&&currentUser.permission==="user"?
										<>
											<li>
												<Link
													to="/user/deposits"
													onClick={toggleSidebar}
												>
													<RiIcons.RiShareForward2Line />
													Deposits
												</Link>
											</li>
											<li>
												<Link
													to="/user/auth"
													onClick={toggleSidebar}
												>
													<AiIcons.AiOutlineUser />
													My Account
												</Link>
											</li>
											<li>
												<Link
													to="/user/withdrawals"
													onClick={toggleSidebar}
												>
													<MdIcons.MdPayments />
													Withdrawals
												</Link>
											</li>
											<li>
												<Link
													to="/user/deposits/buy"
													onClick={toggleSidebar}
												>
													<BsIcons.BsFillHandbagFill />
													Buy Crypto
												</Link>
											</li>
										</>:
										<>
											<li>
												<Link
													to="/app/users"
													onClick={toggleSidebar}
												>
													<FiIcons.FiUsers />
													Users
												</Link>
											</li>
											<li>
												<Link
													to="/app/trading"
													onClick={toggleSidebar}
												>
													<AiIcons.AiOutlineShoppingCart />
													Trading
												</Link>
											</li>
											<li>
												<Link
													to="/app/settings"
													onClick={toggleSidebar}
												>
													<GiIcons.GiCogLock />
													Settings
												</Link>
											</li>
										</>
									}
								</ul>
								<br />
								<button
									id="logout__button"
									onClick={logoutUser}
								>
									Logout
								</button>
								</>
								:
								<ul className="dropdown__ul"
									hidden={pageDropdown}
								>
									<li>
										<Link
											to="/"
											onClick={toggleSidebar}
										>
											<GoIcons.GoHome />
											Home
										</Link>
									</li>
									<li>
										<Link
											to="/auth"
											onClick={toggleSidebar}
										>
											<AiIcons.AiOutlineUser />
											Sign In
										</Link>
									</li>
									<li>
										<Link
											to="/auth/signup"
											onClick={toggleSidebar}
										>
											<AiIcons.AiOutlineUserAdd />
											Sign Up
										</Link>
									</li>
									<li>
										<Link
											to="/about"
											onClick={toggleSidebar}
										>
											<FaIcons.FaUserFriends />
											About Us
										</Link>
									</li>
									<li>
										<Link
											to="/contact"
											onClick={toggleSidebar}
										>
											<AiIcons.AiOutlineMail />
											Contact Us
										</Link>
									</li>
									<li>
										<Link
											to="/privacypolicy"
											onClick={toggleSidebar}
										>
											<BsIcons.BsShieldLock />
											Privacy Policy
										</Link>
									</li>
								</ul>
							}
						</li>
						{
							!token?
							<>
							<li className="nav-item">
								<Link
									to="/trading"
									className="nav-item-link"
									onClick={toggleTradingDropdown}>
									<MdIcons.MdArrowDropDown /> Trading
								</Link>
								<ul className="dropdown__ul" hidden={tradingDropdown}>
									<li>
										<Link
											to="/copytrading"
											onClick={toggleSidebar}
										>
											<FiIcons.FiCopy />
											Copy Trading
										</Link>
									</li>
									<li>
										<Link
											to="/forex"
											onClick={toggleSidebar}
										>
											<AiIcons.AiOutlineMessage />
											Trade Forex
										</Link>
									</li>
									<li>
										<Link
											to="/cryptos"
											onClick={toggleSidebar}
										>
											<AiIcons.AiOutlineCopyright />
											Trade Crypto
										</Link>
									</li>
									<li>
										<Link
											to="/stocks"
											onClick={toggleSidebar}
										>
											<AiIcons.AiOutlineBarChart />
											Trade Stocks
										</Link>
									</li>
									<li>
										<Link
											to="/options"
											onClick={toggleSidebar}
										>
											<FiIcons.FiPieChart />
											Trade Options
										</Link>
									</li>
								</ul>
							</li>
							<li className="nav-item">
								<Link
									to="/mining"
									className="nav-item-link"
									onClick={toggleMiningDropdown}>
									<MdIcons.MdArrowDropDown /> Mining
								</Link>
								<ul className="dropdown__ul" hidden={miningDropdown}>
									<li>
										<Link
											to="/mining/about"
											onClick={toggleSidebar}
										>
											<BiIcons.BiMessageSquareDetail />
											About Mining
										</Link>
									</li>
									<li>
										<Link
											to="/mining/bitcoin"
											onClick={toggleSidebar}
										>
											<AiIcons.AiOutlineCopyright />
											Bitcoin Mining
										</Link>
									</li>
									<li>
										<Link
											to="/mining/dogecoin"
											onClick={toggleSidebar}
										>
											<AiIcons.AiOutlineCopyright />
											Dogecoin Mining
										</Link>
									</li>
								</ul>
							</li>
							</>:''
						}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Sidebar;