import { useEffect } from "react";
import * as GrIcons from "react-icons/gr";
import Footer from "../components/nav/Footer";

const Contact = ({ successToast }) => {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    successToast("Message successfully sent...");
  };

  return (
    <>
      <div className="landingpage__container">
        <section className="trading__section">
          <div className="row justify-content-center">
            <div className="col-12 p-0">
              <div className="card trading__header">
                <h1 className="section-title color-white">Contact Us</h1>
                <p className="section-subtitle color-white">
                  We love to listen and we are eagerly waiting to talk to you
                </p>
              </div>
              <div className="card trading__body">
                <br />
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-6 col-md-11">
                    <form onSubmit={sendMessage}>
                      <div className="row">
                        <div className="form-group col-sm-6">
                          <label
                            id="field-1-label"
                            htmlFor="field-1"
                            className="auth__label"
                          >
                            name
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg custom__input"
                            required={true}
                            name="broker-formname"
                          />
                        </div>
                        <div className="form-group col-sm-6">
                          <label
                            id="field-1-label"
                            htmlFor="field-1"
                            className="auth__label"
                          >
                            email
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-lg custom__input"
                            required={true}
                            name="broker-formemail"
                          />
                        </div>
                        <div className="col-12">
                          <label
                            id="field-1-label"
                            htmlFor="field-1"
                            className="auth__label"
                          >
                            message
                          </label>
                          <textarea
                            type="text"
                            className="form-control form-control-lg custom__input"
                            required={true}
                            name="broker-formmessage"
                          />
                        </div>
                      </div>
                      <button>Send</button>
                    </form>
                  </div>
                  <div className="col-lg-5 col-md-10">
                    <p className="text-center">
                      We are always open and we welcome and questions you have
                      for our team. If you wish to get in touch, please fill out
                      the form below. Someone from our team will get back to you
                      shortly.
                    </p>
                    <span className="contact-icon">
                      <GrIcons.GrMail />
                      tradeoptimal@hydrchain.com
                    </span>
                    <span className="contact-icon">
                      <GrIcons.GrPhone />
                      +1(518)241-3006, +1 (512) 980 -4951
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
