import { useState } from 'react';

export default function AuthToken() {

	const getToken = () => {
		const tokenString = window.localStorage.getItem('cgbroker_token');
		return tokenString;
	};

	const getOTPBoolean = () => {
		const otpBoolean = window.localStorage.getItem('cgbroker_otp');
		return otpBoolean;
	}

	const getUser = () => {
		const activeUser = window.localStorage.getItem('cgbroker_user');
		return JSON.parse(activeUser);
	}

	const getUserOtpID = () => {
		const userOtpID = window.localStorage.getItem('cgbroker_userotpid');
		return JSON.parse(userOtpID);
	}

	const [token, setToken] = useState(getToken());
	const [user, setUser] = useState(getUser());
	const [otp, setOtp] = useState(getOTPBoolean());
	const [userID, setUserID] = useState(getUserOtpID());

	const saveToken = (userToken, userData) => {
		window.localStorage.setItem('cgbroker_token', userToken);
		window.localStorage.setItem('cgbroker_user', JSON.stringify(userData));
		setToken(userToken);
		setUser(userData);
	}

	const saveOTPBoolean = (otpBooleanData, otpUserID) => {
		window.localStorage.setItem('cgbroker_otp', otpBooleanData);
		window.localStorage.setItem('cgbroker_userotpid', JSON.stringify(otpUserID));
		setOtp(otpBooleanData);
		setUserID(otpUserID);
	}

	const deleteToken = () => {
		window.localStorage.removeItem('cgbroker_token');
		window.localStorage.removeItem('cgbroker_user');
		setToken(getToken());
		setUser(getUser());
	}

	const deleteOTPBoolean = () => {
		window.localStorage.removeItem('cgbroker_otp');
		window.localStorage.removeItem('cgbroker_userotpid');
		setOtp(getOTPBoolean());
		setUserID(getUserOtpID());
	}

	return {
		setToken: saveToken,
		removeToken: deleteToken,
		setOTPBoolean: saveOTPBoolean,
		removeOTPBoolean: deleteOTPBoolean,
		token,
		user,
		otp,
		userID
	}

}