import { useState } from "react";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as VscIcons from "react-icons/vsc";
import * as FaIcons from "react-icons/fa";
import Sidebar from "./Sidebar";
// import ProfileImg from "../../assets/img/profile.png";

const AuthNav = ({ removeToken, successToast, currentUser, token }) => {
  const [sidebarState, setSidebarState] = useState(false);

  const toggleSidebar = (e) => {
    setSidebarState(!sidebarState);
  };

  const logoutUser = (e) => {
    e.preventDefault();
    successToast("Successfully logged out -----");
    removeToken();
  };

  return (
    <>
      {sidebarState ? (
        <Sidebar
          toggleSidebar={toggleSidebar}
          token={token}
          successToast={successToast}
          removeToken={removeToken}
          currentUser={currentUser}
        />
      ) : (
        ""
      )}
      <nav className={`navbar navbar-expand sticky-top custom__nav`}>
        <ul className="auth__view">
          <li>
            <i onClick={toggleSidebar}>
              {sidebarState ? <FaIcons.FaTimes /> : <VscIcons.VscThreeBars />}
            </i>
            <span>Trade Optimal</span>
          </li>
          <li>
            <Link className="nav-item-link" to="/user/auth" title="Active User">
              <img
                src={currentUser.profile_photo}
                alt="UserImg"
                title="Active User Image"
              />
            </Link>
            <span
              className="nav-item-link"
              to="/auth/signout"
              title="Sign Out"
              onClick={logoutUser}
            >
              <AiIcons.AiOutlinePoweroff />
            </span>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default AuthNav;
