import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="fixed-top notfound__card">
            <h2>
                404
            </h2>
            <p>Page Not Found</p>
            <span>
                <Link to="/app">
                    Go Home
                </Link>
            </span>
        </div>
    )
}

export default NotFound;